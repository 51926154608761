<div class="bg py-5">
    <div class="container">
        <div class="p-3 bg-white">
            <img src="{{ environmentURL }}/assets/email_banner.PNG" width="100%" />
            <br />
            <br />
            <p>
                Good Afternoon {{ dataRes?.customer?.firstName }}
                {{ dataRes?.customer?.lastName }}
            </p>
            <br />
            <p>
                Thank you for the opportunity to earn your business. We shopped coverage
                across the full Florida auto insurance market to find our best
                recommended options. A summary of what I have proposed:
            </p>
            <ul>
                <li *ngFor="let data of packageList">
                    {{ data }}
                </li>
            </ul>
            <br />
            <p class="text-center">
                <button class="btn btn-type-two" (click)="route()">
                    Proceed to order page
                </button>
            </p>
            <br />
            <p>
                Please note that this policy has a 10% discount already included in the
                rate for participating in the GO telematics program. This telematics
                program does not monitor your location but it does monitor the
                following: time that you drive, how fast you accelerate and your hard
                braking. Upon your renewal you can be rewarded with up to a 20% discount
                so rates would come down even further. The program is only required for
                the first 91 days of your first policy term and then the discount will
                stick with you for the remainder of your policy life, even following you
                at each renewal. The program is not required to write the policy, but we
                do highly recommend the program.
            </p>
            <p>The proposals also include the following options:</p>
            <ul>
                <li>
                    Bodily Injury Liability- Provides coverage when you are at-fault. It
                    pays for the other party’s medical bills, legal bills, and any other
                    compensatory damages.
                </li>
                <li>
                    Spilt Limits- For example, $100,000/$300,000- Pays up to $100,000 per
                    person and $300,000 per accident
                </li>
                <li>
                    As a rule of thumb, we recommend the per accident limit be greater
                    than or equal to your overall assets. If your assets go far beyond
                    $500,000, we recommend carrying an umbrella.
                </li>
                <li>
                    Property Damage- Provides coverage when you are at-fault. It pays for
                    the other party’s vehicle damage and any other property damage such as
                    fallen light posts.
                </li>
                <li>
                    The most common vehicle on the road in America is a Ford F-150, with
                    an average MSRP price of $50,000. If you totaled an F-150 and carried
                    less than $50k in property damage, you would be on the hook to make up
                    the difference.
                </li>
                <li>
                    Uninsured Motorist Coverage- Provides coverage to you and your family
                    if you were involved in an accident with a hit and run driver, a
                    driver that does not carry insurance, or a driver that does not carry
                    adequate insurance
                </li>
                <li>
                    Medical payments- Pays per person the medical bills incurred in an
                    accident to you, your family, and everyone in the car. Pays the amount
                    shown per person.
                </li>
                <li>
                    Personal Injury Protection- Pays you for injuries, no matter who is at
                    fault
                </li>
                <li>Full PIP- covers medical costs, lost wages, and death benefits.</li>
                <li>
                    Collision- Provides coverage for damage to your vehicle when you
                    collide with another vehicle
                </li>
                <li>
                    Comprehensive- Provides coverage for your vehicle due to damage from
                    theft, vandalism, or anything not collision related
                </li>
                <li>
                    Rental Car Coverage- Provides a daily allowance for a rental car if
                    your vehicle is disabled. Pays up to 30 days.
                </li>
                <li>
                    Roadside- Provides coverage for towing, jump starts, fuel delivery,
                    etc.
                </li>
            </ul>
            <br />
            <p>Please review and feel free to give me a call to further discuss</p>
        </div>
    </div>
</div>