import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-payment-preview',
  templateUrl: './payment-preview.component.html',
  styleUrls: ['./payment-preview.component.css'],
})
export class PaymentPreviewComponent {
  @Output() action = new EventEmitter();
  @Output() nextAction = new EventEmitter();
}
