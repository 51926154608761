<main class="d-flex align-items-center mb-3">
    <div class="card formSection">
        <div class="card-header text-center">
            <h1 class="display-6">Payment Preview</h1>
        </div>
        <div class="card-body">
            <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="logoImgStyle mb-3">
                                        <img src="assets/insurance.png">
                                    </div>
                                    <div class="pl-0 pr-0 mb-3">
                                        <div class="contentCard p-4 mb">
                                            <div class="">
                                                <h4>$ xxx</h4>
                                            </div>
                                            <div class="mt-4">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3">
                                                            <span class="text-color-gray text-font14">To</span>
                                                        </div>
                                                        <div class="col-lg-9">
                                                            <span class="text-font14">
                                                                xxxxx xxxxxx</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="spacing-16 pl-0 pr-0">
                                        <div class="contentCard mb-3">
                                            <nav>
                                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                    <button class="nav-link active" id="nav-profile-tab"
                                                        data-bs-toggle="tab" data-bs-target="#nav-profile" type="button"
                                                        role="tab" aria-controls="nav-profile"
                                                        aria-selected="false">Bank Transfer</button>
                                                    <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab"
                                                        data-bs-target="#nav-contact" type="button" role="tab"
                                                        aria-controls="nav-contact" aria-selected="false">Credit
                                                        Card</button>
                                                </div>
                                            </nav>
                                            <div class="tab-content" id="nav-tabContent">
                                                <div class="tab-pane fade show active" id="nav-profile" role="tabpanel"
                                                    aria-labelledby="nav-profile-tab">
                                                    <div class="col-lg-12 p-4 text-center">
                                                        <div class="row">
                                                            <div class="col-lg-12 mb-3">
                                                                <insurance-input inputId="accountName"
                                                                    label="Account Name" [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                            <div class="col-lg-6 mb-3">
                                                                <insurance-input inputId="accountType"
                                                                    label="Account Type" [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                            <div class="col-lg-6 mb-3">
                                                                <insurance-input inputId="routingNumber"
                                                                    label="Routing Number" [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                            <div class="col-lg-12 mb-3">
                                                                <insurance-input inputId="accountNumber"
                                                                    label="Account Number" [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                        </div>
                                                        <insurance-button btnWidth="150" label="Pay" [disabled]="true">
                                                        </insurance-button>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="nav-contact" role="tabpanel"
                                                    aria-labelledby="nav-contact-tab">
                                                    <div class="col-lg-12 p-4 text-center">
                                                        <div class="row">
                                                            <div class="col-lg-12 mb-3">
                                                                <insurance-input inputId="cardno"
                                                                    label="Credit Card Number" [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                            <div class="col-lg-12 mb-3">
                                                                <insurance-input inputId="cardName" label="Name on Card"
                                                                    [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                            <div class="col-lg-5 mb-3">
                                                                <insurance-input inputId="cvv" label="CVV"
                                                                    [disabled]="true">
                                                                </insurance-input>
                                                            </div>
                                                            <div class="col-lg-7 mb-3">
                                                                <insurance-input-date inputId="expiry"
                                                                    label="Expiry date" [disabled]="true">
                                                                </insurance-input-date>
                                                            </div>
                                                        </div>
                                                        <insurance-button btnWidth="150" label="Pay" [disabled]="true">
                                                        </insurance-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 text-center">
                                    <insurance-button class="float-md-start mb-3" btnWidth="150" label="Previous"
                                        (onClick)="action.emit()">
                                    </insurance-button>
                                    <insurance-button class="float-md-end mb-3" btnWidth="150" label="Submit"
                                        (onClick)="nextAction.emit()">
                                    </insurance-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>