const CryptoJS = require('crypto-js');
const SecureStorage = require('secure-web-storage');

import { Injectable } from '@angular/core';
const SECRET_KEY = 'jpbportalApp';

@Injectable({ providedIn: 'root' })
export class AppHelper {

    secureStorage(type: string, key: string, value?: string) {
        const secureStorage = new SecureStorage(localStorage, {
            hash: function hash(key: string) {
                key = CryptoJS.SHA256(key, SECRET_KEY);
                return key.toString();
            },
            encrypt: function encrypt(data: string) {
                data = CryptoJS.AES.encrypt(data, SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: function decrypt(data: { toString: (arg0: string) => string; }) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });

        if (type === 'set' && key) {
            return secureStorage.setItem(key, value);
        }
        if (type === 'get' && key) {
            return secureStorage.getItem(key);
        }
        if (type === 'remove' && key) {
            return secureStorage.removeItem(key);
        }
        return null;
    }
}

