<!-- <app-main-nav> -->
<div class="min-vh-100">
    <div class="container">
        <mat-horizontal-stepper linear #stepper>
            <mat-step>
                <ng-template matStepLabel>Order Page setup</ng-template>
                <app-order-setup (getProductData)="updatedProductData($event, stepper)"></app-order-setup>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Order Page Preview</ng-template>
                <app-orderpage-preview [dataRes]="previewRes" (action)="goBack(stepper)"
                    (nextAction)="goForward(stepper)" [finalUrl]="url"></app-orderpage-preview>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Checkout Preview</ng-template>
                <app-payment-preview (action)="goBack(stepper)" (nextAction)="goCheckout()">
                </app-payment-preview>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>
<!-- </app-main-nav> -->