import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { AppHelper } from 'src/app/app.helper';

@Injectable()
export class AuthGuardService {
  constructor(
    public jwtHelper: JwtHelperService,
    public helper: AppHelper,
    private router: Router
  ) {}
  public isAuthenticated(): boolean {
    const token = this.helper.secureStorage('get', 'token');
    if (token) {
      const helper = new JwtHelperService();
      try {
        if (helper.decodeToken(token)) {
          return !helper.isTokenExpired(token);
        } else {
          this.router.navigate(['/login']);
        }
      } catch {
        this.router.navigate(['/login']);
      }
      return true;
    } else {
      return false;
    }
  }
}
