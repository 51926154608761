import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { AppHelper } from 'src/app/app.helper';
import { WizzipayService } from '../wizzipay.service';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private service: WizzipayService,
    public helper: AppHelper,
    private dialog: MatDialog
  ) {}

  intercept(
    req: HttpRequest<object>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.helper.secureStorage('get', 'token') as string;
    const userId = this.helper.secureStorage('get', 'userId');
    const userData = this.helper.secureStorage('get', 'userData');
    const userDetails = userData ? JSON.parse(userData) : '';
    const imgHeader = `Bearer ${userDetails['accessToken']}`;
    const helper = new JwtHelperService();
    if (userId) {
      try {
        if (helper.decodeToken(token)) {
          if (helper.isTokenExpired(token)) {
            this.router.navigate(['/login']);
            return throwError('unauthorized');
          }
        }
      } catch {
        this.router.navigate(['/login']);
        return throwError('unauthorized');
      }
    }

    const header = {
      token: token || '',
      userid: '',
      authorization: '',
    };

    if (userId) {
      header['userid'] = userId;
    }

    if (req.headers.get('image')) {
      header['authorization'] = imgHeader;
    }

    const authReq = req.clone({
      setHeaders: header,
    });
    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error.status === 403 || error.status === 401) {
          if (this.dialog) {
            this.dialog.closeAll();
          }
          this.router.navigate(['/login']);
          this.service.snackbar('Sorry, you are not authorized!');
          // return Observable.throw(error);
          return throwError(error);
        }
        if (error.status === 502) {
          this.service.snackbar('server temporarily down!');
          // return Observable.throw(error);
          return throwError(error);
        }

        if (error.status === 500) {
          // this.service.snackbar('something went wrong, please try after sometime!');
          // return Observable.throw(error);
          return throwError(error);
        }
        return throwError(error);
      })
    );
  }
}
