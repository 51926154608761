<div class="container d-flex justify-content-center align-items-center h-100">
    <div class="row">
        <div class="col-lg-12 text-center">
            <div class="jumbotron">
                <h1 class="display-4">Register</h1>
            </div>
            <div class="row justify-content-center" [formGroup]="registration">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>First Name</mat-label>
                                <input matInput name="firstName" id="firstName" formControlName="firstName" />
                                <mat-error *ngIf="
                                    registration?.get('firstName')?.hasError('pattern') &&
                                    !registration?.get('firstName')?.hasError('required')
                                  ">
                                    Please enter only alphabets
                                </mat-error>
                                <mat-error *ngIf="registration?.get('firstName')?.hasError('required')">
                                    First name is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>Last Name</mat-label>
                                <input matInput name="lastName" id="lastName" formControlName="lastName" />
                                <mat-error *ngIf="
                                registration?.get('lastName')?.hasError('pattern') &&
                                !registration?.get('lastName')?.hasError('required')
                              ">
                                    Please enter only alphabets
                                </mat-error>
                                <mat-error *ngIf="registration?.get('lastName')?.hasError('required')">
                                    Last name is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>Email</mat-label>
                                <input matInput name="email" id="email" formControlName="email" />
                                <mat-error *ngIf="
                                    registration?.get('email')?.hasError('email') &&
                                    !registration?.get('email')?.hasError('required')
                                  ">
                                    Please enter a valid email address
                                </mat-error>
                                <mat-error *ngIf="registration?.get('email')?.hasError('required')">
                                    Email is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <mat-form-field class="w-100">
                                <mat-label>Password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" name="password" id="password"
                                    formControlName="password" />
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="registration?.get('password')?.hasError('required')">
                                    Password is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="row">
                                <mat-form-field class="col-lg-3 pe-0">
                                    <input matInput placeholder="+1" maxlength="3" formControlName="code" name="code"
                                        id="code" />
                                </mat-form-field>
                                <mat-form-field class="col-lg-9 ps-0">
                                    <mat-label>Phone</mat-label>
                                    <mat-icon matSuffix>phone</mat-icon>
                                    <input matInput formControlName="phone" name="phone" id="phone" data-country="US"
                                        mask="XXX-XXX-XXXX" />
                                    <mat-error *ngIf="
                                    registration?.get('phone')?.hasError('pattern') &&
                                      !registration?.get('phone')?.hasError('required')
                                    ">
                                        Please enter a valid phone number
                                    </mat-error>
                                    <mat-error *ngIf="registration?.get('phone')?.hasError('required')">
                                        Phone Number is required!
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                            <div class="row justify-content-center">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <button id="register" class="btn btn-default mb-2 w-100" type="button"
                                        (click)="submit()">
                                        <span *ngIf="!submitting">Register</span><span *ngIf="submitting"><i
                                                class="fa fa-circle-o-notch fa-spin"></i></span>
                                    </button>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <a class="register-text" (click)="routeBack()"><i class="fa fa-arrow-left"
                                            aria-hidden="true"></i> Back to login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>