import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHelper } from 'src/app/app.helper';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  id = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public helper: AppHelper
  ) {
    activatedRoute.firstChild?.params.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      }
    });
  }

  logout() {
    this.helper.secureStorage('remove', 'token');
    this.helper.secureStorage('remove', 'merchantId');
    this.router.navigate([`/login`]);
  }
}
