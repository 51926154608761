import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizzipayService } from 'src/app/shared/providers/wizzipay.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.css'],
})
export class ContentPreviewComponent implements OnInit {
  merchantId = '';
  campaignId = '';
  environmentURL = '';
  dataRes: { [key: string]: any } = {};
  finalUrl = '';
  packageList: string[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public service: WizzipayService,
    public router: Router
  ) {
    this.environmentURL = environment.autoPortalURL;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.merchantId = params['id'];
      this.campaignId = params['campaignId'];
      this.getPreviewData();
    });
  }

  getPreviewData() {
    this.service.getdata(this.merchantId, this.campaignId).subscribe((res) => {
      this.dataRes = res.data;
      this.finalUrl = `${this.environmentURL}/orderPage/${this.dataRes.merchantId}/${this.dataRes._id}`;
      this.package();
    });
  }

  package() {
    const datas: [{ [key: string]: string }] = [{}];
    for (const key in this.dataRes.package[0]) {
      const payload: { [key: string]: string } = {};
      let count = 0;
      this.dataRes.package.forEach((sub: { [key: string]: string }) => {
        if (key == 'name') {
          payload['Package'] = 'Package';
        } else {
          payload['Package'] = key;
        }
        payload['object' + count++] = sub[key];
      });
      datas.push(payload);
    }
    datas.splice(0, 1);
    datas.forEach((element, index) => {
      if (index == 0) {
        for (const key in element) {
          if (key !== 'Package') {
            this.packageList.push(element[key]);
          }
        }
      }
    });
  }

  route() {
    window.location.href = this.finalUrl;
  }
}
