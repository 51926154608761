import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxPaginationModule } from "ngx-pagination";
import { MainNavComponent } from "./main-nav/main-nav.component";
import { MatModule } from "./mat.module";
import { FilterPipe } from "./pipe/safe-pipe/filter.pipe";
import { SafePipe } from "./pipe/safe-pipe/safe.pipe";

@NgModule({
    declarations: [
        MainNavComponent,
        SafePipe,
        FilterPipe
    ],
    imports: [
        CommonModule,
        MatModule,
        FormsModule,
        ReactiveFormsModule,
        NgxCurrencyModule,
        NgxPaginationModule,
    ],
    exports: [
        MainNavComponent,
        SafePipe,
        FilterPipe,
        MatModule,
        FormsModule,
        ReactiveFormsModule,
        NgxCurrencyModule,
        NgxPaginationModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})

export class SharedModule { }
